<template>
  <base-modal
    modal-title="Edit User"
    popup-class="modal-xl"
  >
    <template #modalBody>
      <div class="px-10 pt-4 pb-8">
        <properties @discard="handleCancel()" />
      </div>
    </template>
  </base-modal>
</template>

<script>
import { defineAsyncComponent } from '@vue/runtime-core';

export default {
    name: 'UserPermissionsFormModal',

    components: {
        BaseModal: defineAsyncComponent(() => import('@/components/generic-components/BaseModal.vue')),
        // UserPermissionsForm: defineAsyncComponent(() => import('./UserPermissionsForm.vue')),
        Properties: defineAsyncComponent(() => import('./Properties.vue'))
    },

    emits: [
        'hide'
    ],

    setup (props, { emit }) {
        const handleCancel = () => {
            emit('hide');
        };

        return {
            handleCancel
        };
    }
};
</script>
